var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ShapeOverlays = function () {
	function ShapeOverlays(elm) {
		_classCallCheck(this, ShapeOverlays);

		this.elm = elm;
		this.path = elm.querySelectorAll('path');
		this.numPoints = 2;
		this.duration = 600;
		this.delayPointsArray = [];
		this.delayPointsMax = 0;
		this.delayPerPath = 200;
		this.timeStart = Date.now();
		this.isOpened = false;
		this.isAnimating = false;
	}

	_createClass(ShapeOverlays, [{
		key: 'toggle',
		value: function toggle() {
			this.isAnimating = true;
			for (var i = 0; i < this.numPoints; i++) {
				this.delayPointsArray[i] = 0;
			}
			if (this.isOpened === false) {
				this.open();
			} else {
				this.close();
			}
		}
	}, {
		key: 'open',
		value: function open() {
			this.isOpened = true;
			if (document.querySelector('.shape-overlays')) {
				document.querySelector('.shape-overlays').classList.add('is-opened');
			}
			this.timeStart = Date.now();
			this.renderLoop();
		}
	}, {
		key: 'close',
		value: function close() {
			$('.navigationWrapper').fadeOut();
			this.isOpened = false;
			if (document.querySelector('.shape-overlays')) {
				document.querySelector('.shape-overlays').classList.remove('is-opened');
			}
			this.timeStart = Date.now();
			this.renderLoop();
		}
	}, {
		key: 'updatePath',
		value: function updatePath(time) {
			var points = [];
			for (var i = 0; i < this.numPoints; i++) {
				var thisEase = this.isOpened ? i == 1 ? ease.cubicOut : ease.cubicInOut : i == 1 ? ease.cubicInOut : ease.cubicOut;
				points[i] = thisEase(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1)) * 100;
			}

			var str = '';
			str += this.isOpened ? 'M 0 0 V ' + points[0] + ' ' : 'M 0 ' + points[0] + ' ';
			for (var i = 0; i < this.numPoints - 1; i++) {
				var p = (i + 1) / (this.numPoints - 1) * 100;
				var cp = p - 1 / (this.numPoints - 1) * 100 / 2;
				str += 'C ' + cp + ' ' + points[i] + ' ' + cp + ' ' + points[i + 1] + ' ' + p + ' ' + points[i + 1] + ' ';
			}
			str += this.isOpened ? 'V 0 H 0' : 'V 100 H 0';

			return str;
		}
	}, {
		key: 'render',
		value: function render() {
			if (this.isOpened) {
				for (var i = 0; i < this.path.length; i++) {
					this.path[i].setAttribute('d', this.updatePath(Date.now() - (this.timeStart + this.delayPerPath * i)));
				}
			} else {
				for (var i = 0; i < this.path.length; i++) {
					this.path[i].setAttribute('d', this.updatePath(Date.now() - (this.timeStart + this.delayPerPath * (this.path.length - i - 1))));
				}
			}
		}
	}, {
		key: 'renderLoop',
		value: function renderLoop() {
			var _this = this;

			this.render();
			if (Date.now() - this.timeStart < this.duration + this.delayPerPath * (this.path.length - 1) + this.delayPointsMax) {
				requestAnimationFrame(function () {
					_this.renderLoop();
				});
			} else {
				this.isAnimating = false;

				if (this.isOpened) {
					$('.navigationWrapper').fadeIn();
					if($('#alertRibbon').height()){
						$('.navigationWrapper').css('max-height', "calc(100vh - " + $('#alertRibbon').height() + "px)");
					}
				}
			}
		}
	}]);

	return ShapeOverlays;
}();

(function () {

	try {
		var elmHamburger = document.querySelector('.openMenu a');
		var gNavItems = document.querySelectorAll('.menuItem');
		var elmOverlay = document.querySelector('.shape-overlays');
		var overlay = new ShapeOverlays(elmOverlay);

		$('.closeMenu a').on('click', function () {
			overlay.close();
			$('body').removeClass('showMenu menuOpen');
		});

		$('header .navigation').on('keydown', function (e) {
			if (e.keyCode == 27) {
				$('.closeMenu a').click();
			}
		});

		elmHamburger.addEventListener('click', function () {
			$('body').addClass('showMenu menuOpen');
			if (overlay.isAnimating) {
				return false;
			}
			overlay.toggle();
			if (overlay.isOpened === true) {
				elmHamburger.classList.add('is-opened-navi');
				for (var i = 0; i < gNavItems.length; i++) {
					gNavItems[i].classList.add('is-opened');
				}
			} else {
				elmHamburger.classList.remove('is-opened-navi');
				for (var i = 0; i < gNavItems.length; i++) {
					gNavItems[i].classList.remove('is-opened');
				}
			}
		});
	} catch (err) {
		console.log('error probably ie 11' + err);
	}
})();